import Image from "next/image"

import Styles from "./MenuFull.module.scss"

const MenuFullItem = ({ imgSrc, imgAlt = "", title, subTitle, onClick }) => {
  return (
    <div className={Styles.ItemWrapper}>
      <div className={Styles.ItemBox}>
        <Image
          src={imgSrc}
          onClick={onClick}
          width="150"
          height="200"
          style={{
            width: "150px",
            cursor: "pointer",
          }}
          alt={imgAlt}
        />
      </div>
      <div className={Styles.ItemTitle}>{title}</div>
      <div className={Styles.ItemSubTitle}>{subTitle}</div>
    </div>
  )
}

export default MenuFullItem
