import { useState, useEffect } from "react"
import { useTranslation } from "next-i18next"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"
import styles from "./CookiesWarning.module.scss"

export default function CookiesWarning({ children }) {
  const { t } = useTranslation("common")
  const [open, setOpen] = useState(false)
  const [allowedOnThisSession, setAllowedOnThisSession] = useState(false)

  useEffect(() => {
    const consent = localStorage.getItem("userConsent")
    if (consent !== "allowed") {
      setOpen(true)
    }
  }, [])

  const allowCookies = () => {
    localStorage.setItem("userConsent", "allowed")
    setAllowedOnThisSession(true)
  }

  const action = (
    <>
      <Button
        color="primary"
        className={styles.button}
        size="small"
        onClick={allowCookies}
      >
        {t("COMMON_COOKIES_GOT_IT")}
      </Button>
    </>
  )

  const message = (
    <div className={styles.message}>{t("COMMON_COOKIES_WARNING")}</div>
  )

  return (
    <>
      {!allowedOnThisSession && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          message={message}
          action={action}
        ></Snackbar>
      )}
      {children}
    </>
  )
}
