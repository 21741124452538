import { useContext } from "react"
import { ModalContext } from "../contexts/modalContext"

export function useModal() {
  const {
    setModalOpen,
    setModalTitle,
    setModalContent,
    setModalCloseable,
    setMoreModalProps,
  } = useContext(ModalContext)

  const openModal = (
    content,
    { closeable = true, title = null, ...moreModalProps } = {}
  ) => {
    setModalContent(content)
    setModalCloseable(closeable)
    setModalOpen(true)
    setModalTitle(title)
    setMoreModalProps(moreModalProps)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return { openModal, closeModal }
}
