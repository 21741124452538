import * as React from "react"
import { buildAbsoluteUrl } from "../../utils/browser"
import { useRouter } from "next/router"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { countriesData } from "../../lib/constants"
import Image from "next/image"
import styles from "./CountryAndLanguageSelector.module.scss"

export default function CountryAndLanguageSelector({
  className = "",
  onSelect,
  language,
  label = "",
  transparent = false,
}) {
  const [value, setValue] = React.useState("")
  const countriesNames = countriesData
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((country) => ({
      label: country.label,
      code: country.value.substring(3, 5),
      value: country.value,
      flag: `https://flagcdn.com/w20/${country.value
        .substring(3, 5)
        .toLowerCase()}.png`,
      flagLarge: `https://flagcdn.com/w40/${country.value
        .substring(3, 5)
        .toLowerCase()}.png`,
    }))

  const selectedCountry = countriesNames.find(
    (country) => country.value === language
  )

  const countriesNamesFiltered = countriesNames.filter(({ label }) => {
    if (value === "") {
      return true
    }

    return label.toLowerCase().includes(value)
  })

  return (
    <>
      {label && <span className="text-xl">{label}</span>}
      <div
        className={`${className} ${styles["country-selector"]} ${
          !transparent && styles["country-selector-with-background"]
        }`}
      >
        <Autocomplete
          id="country-select-demo"
          sx={{ width: "100%" }}
          className={styles["menu-flags"]}
          options={countriesNamesFiltered}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <div onClick={() => onSelect(option.value)}>
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Image
                  loading="lazy"
                  width="20"
                  height="20"
                  src={option.flag}
                  srcSet={`${option.flag} 2x`}
                  alt=""
                />
                {option.label}
              </Box>
            </div>
          )}
          renderInput={(params) => (
            <div
              style={{
                display: "flex",
                padding: 10,

                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Image
                loading="lazy"
                width="30"
                height="30"
                src={selectedCountry.flagLarge}
                srcSet={`${selectedCountry.flagLarge} 2x`}
                alt=""
              />

              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                  placeholder: selectedCountry.label,
                  value: value,
                  onChange: (e) => setValue(e.target.value),

                  style: {
                    color: transparent ? "black" : "white",
                    border: "none",
                    height: "20px",
                  },
                  onFocus: () => {
                    setTimeout(() => {
                      // debugger
                    }, 3000)
                  },
                }}
              ></TextField>
            </div>
          )}
        />
      </div>
    </>
  )
}

export function CountryAndLanguageSelectorWrapper() {
  const { locale: language } = useRouter()

  const changeCountry = (languageAndCountryCode) => {
    const currentPath = window.location.pathname
    const currentSearch = window.location.search
    const languageRegex = /^[a-z]{2}-[A-Z]{2}$/

    const pathParts = currentPath.split("/")

    if (languageRegex.test(pathParts[1])) {
      pathParts.splice(1, 1)
    }

    const newPath = pathParts.join("/")

    const newUrl =
      buildAbsoluteUrl(`${languageAndCountryCode}${newPath}`, false) +
      currentSearch

    window.location.href = newUrl
  }

  return (
    <CountryAndLanguageSelector
      className={styles.CountryAndLanguageSelectorWrapper}
      onSelect={changeCountry}
      language={language}
      showSelectedLabel={true}
    />
  )
}
