import { createContext, useState } from "react"

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [status, setStatus] = useState("loading")
  const [userData, setUserData] = useState({})
  const [userAttributes, setUserAttributes] = useState({})

  return (
    <UserContext.Provider
      value={{
        status,
        setStatus,
        userData,
        setUserData,
        userAttributes,
        setUserAttributes,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
