import { createContext, useState } from "react"
import Header from "../components/Header"
import { useTranslation } from "next-i18next"

const ErrorBoundaryContext = createContext()

const ErrorBoundaryProvider = ({ children }) => {
  const { t } = useTranslation("common")
  const [error, setError] = useState("")

  return (
    <ErrorBoundaryContext.Provider
      value={{
        error,
        setError,
      }}
    >
      {error && (
        <>
          <Header
            pageTitle={t("APP_NAME")}
            displayStartSession={false}
            displayLogo={true}
          />

          <main
            className="mt-20 xl:mt-24  pt-16 w-full md:px-10 max-w-lg mx-auto text-xl font-sans"
            id="page-wrap"
            style={{ padding: "20px" }}
          >
            {error}
          </main>
        </>
      )}
      {!error && children}
    </ErrorBoundaryContext.Provider>
  )
}

export { ErrorBoundaryProvider, ErrorBoundaryContext }
