import { Auth } from "aws-amplify"
import { useContext } from "react"
import { UserContext } from "../contexts/userContext"
import { useSnackbar } from "../hooks/useSnackbar"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"

export default function useUser() {
  const { status, setStatus, userData, setUserData } = useContext(UserContext)
  const { openSnackbar } = useSnackbar()
  const { t } = useTranslation("common")
  const router = useRouter()

  const updateUserStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const userAttributes = await Auth.userAttributes(user)
      setStatus("logged-in")

      const email = userAttributes.find((attr) => attr.Name === "email")?.Value
      const profilePicture = userAttributes.find(
        (attr) => attr.Name === "picture"
      )?.Value

      const profilePictureLarge = userAttributes
        .find((attr) => attr.Name === "picture")
        ?.Value.replace("=s96-c", "")
      const name = userAttributes.find((attr) => attr.Name === "name")?.Value

      const preferredPronoun = userAttributes.find(
        (attr) => attr.Name === "custom:preferred_pronoun"
      )?.Value

      const language = userAttributes.find(
        (attr) => attr.Name === "custom:language"
      )?.Value

      const sessionBackground = userAttributes.find(
        (attr) => attr.Name === "custom:session_background"
      )?.Value

      const sessionMusic = userAttributes.find(
        (attr) => attr.Name === "custom:session_music"
      )?.Value

      const solaceFace = userAttributes.find(
        (attr) => attr.Name === "custom:solace_face"
      )?.Value

      window.localStorage.setItem("userName", name)
      setUserData({
        email,
        profilePicture,
        profilePictureLarge,
        name,
        preferredPronoun,
        language,
        sessionBackground,
        solaceFace,
        sessionMusic,
      })
    } catch (e) {
      setStatus("logged-out")
      setUserData({})
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut()
      setStatus("logged-out")
      setUserData({})
    } catch (error) {
      console.error("Error signing out: ", error)
    }
  }

  const checkLogoutMessage = () => {
    if (window.localStorage.getItem("justLoggedOut") === "true") {
      openSnackbar(
        t("COMMON_LOGGED_OUT", {
          name: window.localStorage.getItem("userName"),
        })
      )

      window.localStorage.removeItem("justLoggedOut")
      window.localStorage.removeItem("userName")
    }
  }

  const checkRedirectUrl = () => {
    const redirectUrl = localStorage.getItem("redirectUrl")
    if (redirectUrl && redirectUrl.includes("/")) {
      localStorage.removeItem("redirectUrl")

      if (
        window.location.href !== redirectUrl &&
        !window.location.href.includes("sign-in") &&
        window.localStorage.getItem("justLoggedIn") === "true"
      ) {
        router.push(redirectUrl)
      }
    }
  }

  const checkLoginMessage = () => {
    if (window.localStorage.getItem("justLoggedIn") === "true") {
      checkRedirectUrl()
      openSnackbar(
        t("COMMON_WELCOME", {
          name: window.localStorage.getItem("userName"),
        })
      )

      window.localStorage.removeItem("justLoggedIn")
    }
  }

  async function updateUserPreferredPronoun(preferredPronoun) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        "custom:preferred_pronoun": preferredPronoun,
      })

      setUserData({ ...userData, preferredPronoun })
    } catch (error) {
      console.error("Error updating user preferred pronoun:", error)
    }
  }

  async function updateUserLanguage(language) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        "custom:language": language,
      })
      setUserData({ ...userData, language })
    } catch (error) {
      console.error("Error updating user language:", error)
    }
  }

  async function updateSessionBackground(sessionBackground) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        "custom:session_background": sessionBackground,
      })
      setUserData({ ...userData, sessionBackground })
    } catch (error) {
      console.error("Error updating sessionBackground:", error)
    }
  }

  async function updateSessionMusic(sessionMusic) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        "custom:session_music": sessionMusic,
      })
      setUserData({ ...userData, sessionMusic })
    } catch (error) {
      console.error("Error updating sessionBackground:", error)
    }
  }

  async function updateSolaceFace(solaceFace) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        "custom:solace_face": solaceFace,
      })
      setUserData({ ...userData, solaceFace })
    } catch (error) {
      console.error("Error updating solaceFace:", error)
    }
  }

  function isLoggedIn() {
    return status === "logged-in"
  }

  const userPhoto = userData.profilePicture || "/assets/icon-person.svg"
  const userPhotoLarge =
    userData.profilePictureLarge || "/assets/icon-person.svg"

  return {
    updateSolaceFace,
    updateUserStatus,
    updateUserPreferredPronoun,
    updateUserLanguage,
    updateSessionBackground,
    updateSessionMusic,
    status,
    userData,
    userPhotoLarge,
    userPhoto,
    logout,
    isLoggedIn,
    checkLogoutMessage,
    checkLoginMessage,
  }
}
