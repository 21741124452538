import { createContext, useState, useEffect } from "react"

import { useRouter } from "next/router"
import useUser from "../hooks/useUser"

const SettingsContext = createContext()

const SettingsProvider = ({ children }) => {
  const {
    updateSessionBackground,
    updateSolaceFace,
    updateSessionMusic,
    userData,
  } = useUser()
  const [solaceFace, setSolaceFaceInternal] = useState(
    "solace-face-en-GB-male.webp"
  )

  const [sessionBackground, setSessionBackgroundInternal] = useState(
    "solace-background-3.webp"
  )
  const [sessionBackgroundMusic, setSessionBackgroundMusicInternal] =
    useState("on")

  const {
    locale,
    query: { gender: genderQuery = "female" },
  } = useRouter()

  const [gender, setGenderInternal] = useState(genderQuery)

  const setGender = (newGender) => {
    setGenderInternal(newGender)
  }

  useEffect(() => {
    if (
      userData.sessionBackground &&
      userData.sessionBackground !== sessionBackground
    ) {
      setSessionBackgroundInternal(userData.sessionBackground)
    }
  }, [userData])

  useEffect(() => {
    if (userData.solaceFace && userData.solaceFace !== solaceFace) {
      setSolaceFaceInternal(userData.solaceFace)
    }
  }, [userData])

  useEffect(() => {
    if (
      userData.sessionMusic &&
      userData.sessionMusic !== sessionBackgroundMusic
    ) {
      setSessionBackgroundMusicInternal(userData.sessionMusic)
    }
  }, [userData])

  const setSessionBackground = (newSessionBackground) => {
    updateSessionBackground(newSessionBackground)
    setSessionBackgroundInternal(newSessionBackground)
  }

  const setSessionBackgroundMusic = (newStatus) => {
    updateSessionMusic(newStatus)
    setSessionBackgroundMusicInternal(newStatus)
  }

  const setSolaceFace = (newSolaceFace) => {
    updateSolaceFace(newSolaceFace)
    setSolaceFaceInternal(newSolaceFace)
  }

  return (
    <SettingsContext.Provider
      value={{
        sessionBackgroundMusic,
        setSessionBackgroundMusic,
        sessionBackground,
        setSessionBackground,
        solaceFace,
        setSolaceFace,
        gender,
        setGender,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsProvider, SettingsContext }
