import { createContext, useContext, useState } from "react"
import Notification from "../components/Notification"
const NotificationContext = createContext({
  showNotification: () => {},
  message: "",
})

export const useNotificationContext = () => useContext(NotificationContext)

export const NotificationContent = ({ children }) => {
  const { notification, closeNotification } = useContext(NotificationContext)

  return (
    <>
      {children}
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={closeNotification}
      />
    </>
  )
}

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    message: "",
    type: "error",
  })

  const showNotification = ({ message, type = "error", time = 2500 }) => {
    setNotification({ message, type })

    if (time === 0) return

    setTimeout(() => {
      closeNotification()
    }, time)
  }

  const closeNotification = () => {
    setNotification({ message: "", type: "error" })
  }

  return (
    <NotificationContext.Provider
      value={{ notification, showNotification, closeNotification }}
    >
      <NotificationContent>{children}</NotificationContent>
    </NotificationContext.Provider>
  )
}
