import { useContext } from "react"
import { SnackbarContext } from "../contexts/snackbarContext"

export function useSnackbar() {
  const { setSnackbarOpen, setSnackbarMessage } = useContext(SnackbarContext)

  const openSnackbar = (message) => {
    setSnackbarMessage(message)
    setSnackbarOpen(true)
  }

  return { openSnackbar }
}
