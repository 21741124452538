import React from "react"

const Notification = ({ message, type = "error" }) => {
  const borderColor = type === "error" ? "border-rose-600" : "border-black"
  const bgColor = type === "error" ? "bg-rose-600" : "bg-black"

  return (
    message && (
      <div
        className={`fixed top-16 right-4 ${bgColor} p-4 rounded-lg border-4 ${borderColor}`}
      >
        <div className="text-white font-bold">{message}</div>
      </div>
    )
  )
}

export default Notification
