import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { createContext, useState } from "react"

const ModalContext = createContext({})

const ModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState("")
  const [modalCloseable, setModalCloseable] = useState(true)
  const [modalTitle, setModalTitle] = useState(null)
  const [moreModalProps, setMoreModalProps] = useState({})

  const modalProps = {}

  if (modalCloseable) {
    modalProps.onClose = () => setModalOpen(false)
  }

  const modalTopContent = modalCloseable && (
    <DialogTitle sx={{ m: 1, p: 2 }}>
      {modalTitle}
      <IconButton
        aria-label="close"
        onClick={modalProps.onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )

  return (
    <>
      <ModalContext.Provider
        value={{
          modalOpen,
          setModalOpen,
          modalContent,
          setModalContent,
          setModalCloseable,
          setModalTitle,
          moreModalProps,
          setMoreModalProps,
        }}
      >
        {children}
      </ModalContext.Provider>

      <Dialog
        PaperProps={{
          sx: {
            width: "720px",
            maxWidth: "100vw",
            ...(moreModalProps.style || {}),
          },
        }}
        open={modalOpen}
        {...modalProps}
        {...moreModalProps}
      >
        {modalTopContent}
        <DialogContent>{modalContent}</DialogContent>
      </Dialog>
    </>
  )
}

export { ModalContext, ModalProvider }
