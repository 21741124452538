/* eslint-disable @next/next/no-img-element */
import "../styles/globals.css"
import Head from "next/head"
import { appWithTranslation } from "next-i18next"

import { UserProvider } from "../contexts/userContext"
import { ErrorBoundaryProvider } from "../contexts/errorBoundaryContext"
import { NotificationProvider } from "../contexts/notificationContext"
import { LanguageProvider } from "../contexts/languageContext"
import { SnackbarProvider } from "../contexts/snackbarContext"
import { ModalProvider } from "../contexts/modalContext"
import { SettingsProvider } from "../contexts/settingsContext"
import { ThemeMUIProvider } from "../contexts/themeMUIContext"
import { useTranslation } from "next-i18next"

import Auth from "../components/Auth"
import CookiesWarning from "../components/CookiesWarning"

import Script from "next/script"

import "react-tooltip/dist/react-tooltip.css"

function MyApp({ Component, pageProps, router }) {
  const { t, i18n } = useTranslation("common", "tests", "voxme")

  const language = i18n.language
  const languageCamelCase = (language || "").split("-").join("_")

  const routesMetaTags = {
    "/": {
      title: t("HOME_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/start-session": {
      title: t("SESSION_START_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/free-depression-test": {
      title: t("TESTS_DEPRESSION_PHQ9_TITLE", { ns: "tests" }),
      description: t("TESTS_DEPRESSION_PHQ9_META", { ns: "tests" }),
    },
    "/online-adhd-testing": {
      title: t("TEST_ADHD_ASRSV1_TITLE", { ns: "tests" }),
      description: t("TEST_ADHD_ASRSV1_META", { ns: "tests" }),
    },
    "/anxiety-test": {
      title: t("TEST_ANXIETY_TITLE", { ns: "tests" }),
      description: t("TEST_ANXIETY_META", { ns: "tests" }),
    },
    "/stress-test": {
      title: t("TEST_STRESS_PSS_TITLE", { ns: "tests" }),
      description: t("TEST_STRESS_PSS_META", { ns: "tests" }),
    },
    "/privacy-policy": {
      title: t("PRIVACY_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/sign-in": {
      title: t("SIGNIN_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/sign-up": {
      title: t("SIGNUP_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/sign-in-voxme": {
      title: t("VOXME_SIGNIN_TITLE", { ns: "voxme" }),
      description: t("VOXME_HOME_DESCRIPTION", { ns: "voxme" }),
    },
    "/sign-up-voxme": {
      title: t("VOXME_SIGNUP_TITLE", { ns: "voxme" }),
      description: t("VOXME_HOME_DESCRIPTION", { ns: "voxme" }),
    },
    "/terms-and-conditions": {
      title: t("TERMS_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/session": {
      title: t("SESSION_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/session-action-points": {
      title: t("SESSION_ACTION_POINTS_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
    "/session-review": {
      title: t("SESSION_REVIEW_TITLE"),
      description: t("HOME_DESCRIPTION"),
    },
  }

  const routeMetaTags = routesMetaTags[router.pathname] || routesMetaTags["/"]

  const facebookImageURL = `https://${
    pageProps.host
  }/assets/share/Facebook-${String(language).toUpperCase()}.png`

  const pinterestImageURL = `https://${
    pageProps.host
  }/assets/share/Pinterest-${String(language).toUpperCase()}.png`

  return (
    <>
      <Script id="pinterest" strategy="lazyOnload">
        {`
          !function(e) {
            if(!window.pintrk){
              window.pintrk = function () {
                window.pintrk.queue.push(Array.prototype.slice.call(arguments));
              };
              var n=window.pintrk;
              n.queue=[],n.version="3.0";
              var t=document.createElement("script");
              t.async=!0;
              t.src=e;
              var r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r);
            }
          }("https://s.pinimg.com/ct/core.js");
          pintrk('load', '2613912040667', {em: '<user_email_address>'});
          pintrk('page');
        `}
      </Script>
      <Script id="ms_clarity" strategy="lazyOnload">
        {`
          (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "iemz0i7mjr");
        `}
      </Script>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://ct.pinterest.com/v3/?event=init&tid=2613912040667&pd[em]=<hashed_email_address>&noscript=1"
        />
      </noscript>

      <Script id="load-hotjar" strategy="lazyOnload">
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3597249,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>

      <Script
        id="load-google-analytics"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload" id="configure-google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
      `}
      </Script>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#172036" />
        <title>{routeMetaTags.title}</title>
        <meta name="description" content={routeMetaTags.description} />
        <meta name="apple-mobile-web-app-title" content="Mind-r.ai" />
        <meta name="application-name" content="Mind-r.ai" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
        <meta property="og:image" content={facebookImageURL} />
        <meta property="og:site_name" content="Mind-R.ai" />
        <meta property="og:locale" content={languageCamelCase} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={routeMetaTags.title} />
        <meta property="og:description" content={routeMetaTags.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mind_r_ai" />
        <meta name="twitter:creator" content="@_rxluz" />
        <meta name="twitter:title" content={routeMetaTags.title} />
        <meta name="twitter:description" content={routeMetaTags.description} />
        <meta name="twitter:image" content={facebookImageURL} />
        <meta property="pinterest:image" content={pinterestImageURL} />
        <meta property="product:price:amount" content="0.00" />
        <meta property="product:price:currency" content="USD" />
        <meta property="og:availability" content="instock" />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="" id="outer-container">
        <ThemeMUIProvider>
          <UserProvider>
            <Auth>
              <SettingsProvider>
                <ErrorBoundaryProvider>
                  <SnackbarProvider>
                    <LanguageProvider>
                      <NotificationProvider>
                        <ModalProvider>
                          <CookiesWarning>
                            <Component {...pageProps} />
                          </CookiesWarning>
                        </ModalProvider>
                      </NotificationProvider>
                    </LanguageProvider>
                  </SnackbarProvider>
                </ErrorBoundaryProvider>
              </SettingsProvider>
            </Auth>
          </UserProvider>
        </ThemeMUIProvider>
      </div>
    </>
  )
}

export default appWithTranslation(MyApp)
