import Snackbar from "@mui/material/Snackbar"
import { createContext, useState } from "react"
import { isMobile } from "../utils/browser"

const SnackbarContext = createContext({})

const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  return (
    <>
      <SnackbarContext.Provider
        value={{
          snackbarOpen,
          setSnackbarOpen,
          snackbarMessage,
          setSnackbarMessage,
        }}
      >
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{
          vertical: isMobile() ? "top" : "bottom",
          horizontal: "right",
        }}
        autoHideDuration={12000}
        onClose={() => setSnackbarOpen(false)}
      >
        <div
          className="font-sans"
          style={{
            border: "2px solid rgb(23 31 54)",
            borderRadius: "20px",
            padding: "15px",
            backgroundColor: "rgb(23 31 54)",
            maxWidth: "300px",
            color: "white",
          }}
        >
          {snackbarMessage}
        </div>
      </Snackbar>
    </>
  )
}

export { SnackbarContext, SnackbarProvider }
