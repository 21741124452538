import { useState, useEffect } from "react"
import { styled, alpha } from "@mui/material/styles"
import Menu from "@mui/material/Menu"
import { useShare } from "../hooks/useShare"
import MenuItem from "@mui/material/MenuItem"
import LogoutIcon from "@mui/icons-material/Logout"
import { useTranslation } from "next-i18next"
import useUser from "../hooks/useUser"

import ShareIcon from "@mui/icons-material/Share"
import Avatar from "@mui/material/Avatar"
import { FaUserCircle, FaSignInAlt } from "react-icons/fa"
import {
  goToSignUp,
  goToSignIn,
  goToSignUpVoxme,
  goToSignInVoxme,
  goStartSession,
  goStartAnonymousSession,
} from "../utils/browser"

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      padding: "20px",
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export default function HeaderUserMenu({ mode = "mind-r" }) {
  const { t } = useTranslation("common")
  const [anchorEl, setAnchorEl] = useState(null)
  const { openShare } = useShare()
  const { logout, userData, userPhoto, status, isLoggedIn } = useUser()
  const { checkLoginMessage } = useUser()

  const [displayAvatar, setDisplayAvatar] = useState(false)

  useEffect(() => {
    checkLoginMessage()
    setDisplayAvatar(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const sizes = {
    small: { width: 24, height: 24 },
    medium: { width: 48, height: 48 },
  }

  const borderColor = isLoggedIn() ? "#ACFFCD" : "#E0E0F1"
  const padding = isLoggedIn() ? "0" : "12px"

  const avatar = (size, onClick = () => {}) =>
    displayAvatar && (
      <Avatar
        alt={userData.name || t("COMMON_USER_OPTIONS")}
        className="ml-5 cursor-pointer"
        imgProps={{ referrerPolicy: "no-referrer" }}
        src={userPhoto}
        onClick={onClick}
        style={{
          ...sizes[size],
          borderWidth: "2px",
          borderRadius: "15px",
          borderStyle: "solid",
          borderColor,
          padding,
        }}
        variant="rounded"
      />
    )

  return (
    <>
      {avatar("medium", handleClick)}
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {status !== "logged-in" && (
          <MenuItem
            disableRipple
            onClick={mode === "mind-r" ? goToSignIn : goToSignInVoxme}
            id="signin"
            className="menu-item flex text-black items-center"
          >
            <FaSignInAlt size={20} className="mr-5 " /> {t("COMMON_SIGN_IN")}
          </MenuItem>
        )}

        {status !== "logged-in" && (
          <MenuItem
            disableRipple
            onClick={mode === "mind-r" ? goToSignUp : goToSignUpVoxme}
            id="signup"
            className="menu-item flex text-black items-center"
          >
            <FaUserCircle size={20} className="mr-5" /> {t("COMMON_SIGN_UP")}
          </MenuItem>
        )}

        {mode === "mind-r" && (
          <>
            {status === "logged-in" && (
              <MenuItem
                disableRipple
                onClick={goStartSession}
                className="menu-item flex text-black items-center"
              >
                {t("COMMON_TALK_TO_SOLACE_NOW")}
              </MenuItem>
            )}
            {status === "logged-in" && (
              <MenuItem
                disableRipple
                onClick={goStartAnonymousSession}
                className="menu-item flex text-black items-center"
              >
                {t("COMMON_START_ANONYMOUS_SESSION")}
              </MenuItem>
            )}
          </>
        )}

        <MenuItem
          onClick={openShare}
          id="share"
          className="menu-item flex text-black items-center"
        >
          <ShareIcon size={20} className="mr-5 " />{" "}
          {t("COMMON_SHARE_WITH_FRIENDS")}
        </MenuItem>

        {status === "logged-in" && (
          <MenuItem
            onClick={logout}
            id="signin"
            className="menu-item flex text-black items-center"
          >
            <LogoutIcon size={20} className="mr-5 " /> {t("COMMON_LOGOUT")}
          </MenuItem>
        )}
      </StyledMenu>
    </>
  )
}
