import { createContext, useState } from "react"
import { useRouter } from "next/router"

export const LanguageContext = createContext(null)

export const LanguageProvider = ({ children }) => {
  const router = useRouter()
  const { locale } = router

  const languageExternal = locale

  const [language, setLanguage] = useState(languageExternal || "")

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}
