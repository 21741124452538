import validator from "validator"
import React, { useState, useRef, useEffect } from "react"
import { Checkbox, FormControlLabel } from "@mui/material"
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined"
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied"
import Rating from "@mui/material/Rating"
import { styled } from "@mui/material/styles"
import { isMobile } from "../utils/browser"
import copy from "copy-to-clipboard"
import { useTranslation } from "next-i18next"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"

function NumberIcon({ num }) {
  return (
    <div
      style={{
        borderRadius: "50%",
        width: "45px",
        height: "45px",
        marginRight: "5px",
        textAlign: "center",
        padding: "2px",
        borderWidth: "4px",
        borderStyle: "solid",
        // #c5c5c5

        fontSize: "24px",
        fontFamily: "DM Sans",
      }}
    >
      {num}
    </div>
  )
}

function DateOfBirth({ onChange }) {
  const { t } = useTranslation("common")
  const [day, setDay] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")

  const handleDayChange = (event) => {
    setDay(event.target.value)

    onChange({ year, month, day: event.target.value * 1 })
  }

  const handleMonthChange = (event) => {
    setMonth(event.target.value * 1)
    setDay("")
    onChange({ year, month: event.target.value * 1, day: "" })
  }

  const handleYearChange = (event) => {
    setYear(event.target.value * 1)
    setMonth("")
    setDay("")

    onChange({ year: event.target.value * 1, month: "", day: "" })
  }

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate()
  }

  const years = []
  const currentYear = new Date().getFullYear()
  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push(i)
  }

  const months = year ? Array.from({ length: 12 }, (_, i) => i + 1) : []

  const days = month
    ? Array.from({ length: getDaysInMonth(month, year) }, (_, i) => i + 1)
    : []

  const selectProps = {
    className: "px-4 mx-2 py-3 rounded-lg border-2",
    style: {
      borderColor: "#A8B9BF",
      borderWidth: "2px",
      borderRadius: "20px",
      marginBottom: "10px",
    },
  }

  return (
    <div className="flex-col md:flex-row flex justify-center">
      <select {...selectProps} onChange={handleYearChange} value={year}>
        <option value="">{t("COMMON_SELECT_YEAR")}</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      {year && (
        <select {...selectProps} onChange={handleMonthChange} value={month}>
          <option value="">{t("COMMON_SELECT_MONTH")}</option>
          {months.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      )}
      {month && (
        <select {...selectProps} onChange={handleDayChange} value={day}>
          <option value="">{t("COMMON_SELECT_DAY")}</option>
          {days.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

const customFaceIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ fontSize: 52 }} />,
    label: 1,
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ fontSize: 52 }} />,
    label: 2,
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ fontSize: 52 }} />,
    label: 3,
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={{ fontSize: 52 }} />,
    label: 4,
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={{ fontSize: 52 }} />,
    label: 5,
  },
}

const customNumberIcons = {
  0: {
    icon: <NumberIcon num={0} />,
    label: 0,
  },
  1: {
    icon: <NumberIcon num={1} />,
    label: 1,
  },
  2: {
    icon: <NumberIcon num={2} />,
    label: 2,
  },
  3: {
    icon: <NumberIcon num={3} />,
    label: 3,
  },
  4: {
    icon: <NumberIcon num={4} />,
    label: 4,
  },
  5: {
    icon: <NumberIcon num={5} />,
    label: 5,
  },
  6: {
    icon: <NumberIcon num={6} />,
    label: 6,
  },
  7: {
    icon: <NumberIcon num={7} />,
    label: 7,
  },
  8: {
    icon: <NumberIcon num={8} />,
    label: 8,
  },
  9: {
    icon: <NumberIcon num={9} />,
    label: 9,
  },
  10: {
    icon: <NumberIcon num={10} />,
    label: 10,
  },
}

function IconFaceContainer(props) {
  const { value, ...other } = props
  return (
    <span style={{ marginBottom: "10px" }} {...other}>
      {customFaceIcons[value].icon}
    </span>
  )
}

function IconNumberContainer(props) {
  const { value, ...other } = props
  return (
    <span style={{ marginBottom: "10px" }} {...other}>
      {customNumberIcons[value].icon}
    </span>
  )
}

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
  "& .MuiRating-iconFilled": {
    color: "#172036",
    borderColor: "#172036",
    fontWeight: "bold",
  },
  "& .MuiRating-iconFilled div": {
    color: "#172036",
    borderColor: "#172036",
    fontWeight: "bold",
  },
}))

export default function FormInput({
  label,
  description = "",
  type,
  value,
  onChange,
  showCopyButton = false,
  options,
  validate = "",
  validateMessage = "",
  disabled,
  textareaRows = 3,
  textareaMaxLength,
  inputProps = {},
}) {
  const { t } = useTranslation("common")
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copiedRef = useRef(null)

  const renderOptions = () => {
    return options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))
  }

  const onCopy = () => {
    copy(value)

    setShowCopiedMessage(true)

    clearTimeout(copiedRef.current)

    copiedRef.current = setTimeout(() => {
      setShowCopiedMessage(false)
    }, 3000)
  }

  useEffect(() => {
    return () => {
      clearTimeout(copiedRef.current)
    }
  })

  const handleBoxClick = (selectedValue) => {
    if (type === "select-boxes" && onChange) {
      const event = {
        target: {
          value: selectedValue,
        },
      }
      onChange(event)
    }
  }

  const renderSelectBoxes = () => {
    const boxPerLine = isMobile() ? 2 : 3
    const rows = Math.ceil(options.length / boxPerLine)

    return Array.from({ length: rows }, (_, rowIndex) => {
      return (
        <div key={rowIndex} className="flex font-sans">
          {options
            .slice(rowIndex * boxPerLine, (rowIndex + 1) * boxPerLine)
            .map((option, index, total) => (
              <div
                key={option.value}
                onClick={() => handleBoxClick(option.value)}
                style={{
                  background: value === option.value ? "#172036" : "white",
                  borderColor: value === option.value ? "#172036" : " #A8B9BF",
                  borderWidth: "2px",
                  color: value === option.value ? "white" : "black",
                  borderRadius: "20px",
                  cursor: "pointer",
                  marginRight:
                    index < boxPerLine - 1 && total.length - 1 > index
                      ? "0.5rem"
                      : "0",
                }}
                className={`p-4 flex flex-grow mb-2 rounded-lg justify-between border-2 `}
              >
                <span>{option.label}</span>

                {option.tooltip && !isMobile() && (
                  <Tooltip title={option.tooltip}>
                    <InfoIcon />
                  </Tooltip>
                )}
              </div>
            ))}
        </div>
      )
    })
  }

  const copyButton = showCopyButton && (
    <button
      onClick={onCopy}
      style={{
        position: "absolute",
        right: "20px",
        top: "12px",
        color: "rgb(0, 25, 255)",
      }}
    >
      {showCopiedMessage ? t("COMMON_COPIED") : t("COMMON_COPY")}
    </button>
  )

  return (
    <div className="flex flex-col w-full mb-8">
      {[
        "select",
        "text",
        "password",
        "textarea",
        "number-scale",
        "smile-boxes",
        "star-scale",
        "select-boxes",
      ].includes(type) && (
        <label
          htmlFor={label}
          className="mb-2 font-sans tracking-wider text-xl"
        >
          {label}
          {description && (
            <span className="block text-sm py-10 text-gray-100">
              {description}
            </span>
          )}
        </label>
      )}

      {type === "select" && (
        <select
          className="px-4 py-3 font-sans rounded-lg border-2 "
          id={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          style={{
            borderColor: "#172036",
            borderWidth: "2px",
            borderRadius: "20px",
          }}
        >
          {renderOptions()}
        </select>
      )}

      {type === "smile-boxes" && (
        <StyledRating
          name="highlight-selected-only"
          defaultValue={value}
          onChange={onChange}
          style={{ flexDirection: "row", flexWrap: "wrap" }}
          IconContainerComponent={IconFaceContainer}
          getLabelText={(value) => customFaceIcons[value].label}
          highlightSelectedOnly
        />
      )}

      {type === "star-scale" && (
        <Rating
          name="size-large"
          defaultValue={value}
          onChange={onChange}
          size="large"
        />
      )}

      {type === "number-scale" && (
        <StyledRating
          name="highlight-selected-only"
          defaultValue={value}
          onChange={onChange}
          max={10}
          IconContainerComponent={IconNumberContainer}
          style={{ flexDirection: "row", flexWrap: "wrap" }}
          getLabelText={(value) => customNumberIcons[value].label}
          highlightSelectedOnly
        />
      )}

      {["text", "password"].includes(type) && (
        <div style={{ position: "relative", width: "100%" }}>
          <input
            className="px-4 py-3 rounded-lg border-2 "
            id={label}
            type={type}
            value={value}
            disabled={disabled}
            onChange={onChange}
            {...inputProps}
            style={{
              borderColor: "#A8B9BF",
              borderWidth: "2px",
              borderRadius: "20px",
              width: "100%",
            }}
          />

          {copyButton}
          {validate && (
            <ValidateField
              validateMessage={validateMessage}
              type={validate}
              value={value}
            />
          )}
        </div>
      )}

      {["textarea"].includes(type) && (
        <div style={{ position: "relative", width: "100%" }}>
          <textarea
            rows={textareaRows}
            className="px-4 py-3 rounded-lg border-2 "
            id={label}
            type={type}
            value={value}
            disabled={disabled}
            onChange={onChange}
            maxLength={textareaMaxLength}
            {...inputProps}
            style={{
              borderColor: "#A8B9BF",
              borderWidth: "2px",
              borderRadius: "20px",
              width: "100%",
              paddingRight: showCopyButton ? "130px" : "0px",
            }}
          />

          {copyButton}

          {textareaMaxLength && (
            <div>
              {t("COMMON_AVAILABLE")}: {textareaMaxLength - value.length}{" "}
            </div>
          )}
        </div>
      )}

      {["dob"].includes(type) && <DateOfBirth onChange={onChange} />}

      {type === "checkbox" && (
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={onChange}
              disabled={disabled}
              sx={{
                color: "#d5dbe9",
                zoom: 1.5,
                "&.Mui-checked": {
                  color: "rgb(0, 25, 255)",
                },
              }}
            />
          }
          label={<span className="text-xl">{label}</span>}
        />
      )}

      {type === "select-boxes" && renderSelectBoxes()}
    </div>
  )
}

export const isInvalid = (type, value) => {
  if (type === "url" || type === "producthunt") {
    if (
      !validator.isURL(value, {
        protocols: ["http", "https"],
        require_tld: true,
        require_protocol: true,
      })
    ) {
      return true // Invalid if not a valid URL
    }

    if (
      type === "producthunt" &&
      !value.startsWith("https://www.producthunt.com/posts")
    ) {
      return true // Invalid if it's a producthunt URL and doesn't start with the producthunt prefix
    }
  }

  return false // Consider it valid in all other cases
}

function ValidateField({ type, value, validateMessage }) {
  if (isInvalid(type, value)) {
    return <div className="text-red-500 text-sm">{validateMessage}</div>
  }

  return <></>
}
