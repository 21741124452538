const countriesDataFull = require("./constants.countries")

export const pronounOptions = [
  { value: "", label: "Select Pronoun" },
  { value: "ey/em", label: "Ey/Em" },
  { value: "fae/faer", label: "Fae/Faer" },
  { value: "he/him", label: "He/Him" },
  { value: "per/per", label: "Per/Per" },
  { value: "she/her", label: "She/Her" },
  { value: "they/them", label: "They/Them" },
  { value: "ve/ver", label: "Ve/Ver" },
  { value: "xe/xem", label: "Xe/Xem" },
  { value: "ze/hir", label: "Ze/Hir" },
  { value: "other", label: "Other" },
]

export const languageOptions = [
  { value: "", label: "Select Language" },
  { value: "en", label: "English" },
  { value: "es", label: "Español" },
  { value: "pt", label: "Português" },
  { value: "zh", label: "中文" },
  { value: "hi", label: "हिन्दी" },
  { value: "ar", label: "العربية" },
  { value: "bn", label: "বাংলা" },
  { value: "ru", label: "Русский" },
  { value: "ja", label: "日本語" },
  { value: "ms", label: "Bahasa Melayu" },
  { value: "de", label: "Deutsch" },
  { value: "fr", label: "Français" },
  { value: "pa", label: "ਪੰਜਾਬੀ" },
  { value: "ko", label: "한국어" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "ta", label: "தமிழ்" },
  { value: "it", label: "Italiano" },
  { value: "tr", label: "Türkçe" },
]

export const solaceGenders = {
  male: ["Ele/Dele", "He/Him", "Lui/Lui", "Él/De él", "Il/De lui", "er/sein"],
  female: [
    "Ela/Dela",
    "She/Her",
    "Lei/Lei",
    "Ella/De ella",
    "Elle/De elle",
    "sie/ihr",
  ],
  nonBinary: [
    "Elu/Delu",
    "They/Them",
    "Loro/Lor",
    "Elle/De elle",
    "Iel/De iel",
    "Siad/Aca",
    "xier/xies",
  ],
}

export const languagesPronouns = {
  pt: ["Ela/Dela", "Ele/Dele"],
  en: ["She/Her", "He/Him"],
  it: ["Lei/Lei", "Lui/Lui", "Loro/Lor"],
  es: ["Ella/De ella", "Él/De él", "Elle/De elle"],
  fr: ["Elle/De elle", "Il/De lui", "Iel/De iel"],
  ga: ["Sí/Aici", "Sé/Aige", "Siad/Aca"],
  de: ["sie/ihr", "er/sein", "xier/xies"],
}

const awsConfig = {
  aws_project_region: process.env.NEXT_PUBLIC_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.NEXT_PUBLIC_OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "", // This value will be replaced in getUpdatedAwsConfig()
    redirectSignOut: "", // This value will be replaced in getUpdatedAwsConfig()
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL", "PHONE_NUMBER"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL", "NAME", "PICTURE"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
}

const countriesData = countriesDataFull.female

export { countriesData, countriesDataFull, awsConfig }
