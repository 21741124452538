import { useModal } from "./useModal"
import FormInput from "../components/FormInput"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const ShareContent = () => {
  const { t } = useTranslation("common")

  const { locale: language } = useRouter()

  const WEBSITE_URL = `https://mind-r.ai/${language}`

  const shareButtonsData = [
    {
      Component: EmailShareButton,
      Icon: EmailIcon,
      props: {
        subject: t("COMMON_SHARE_SUBJECT"),
        body: t("COMMON_SHARE_BODY"),
        separator: "",
      },
    },
    {
      Component: FacebookShareButton,
      Icon: FacebookIcon,
      props: {
        quote: t("COMMON_SHARE_QUOTE"),
        hashtag: t("COMMON_SHARE_HASHTAG"),
      },
    },
    {
      Component: PinterestShareButton,
      Icon: PinterestIcon,
      props: {
        media: t("COMMON_SHARE_MEDIA"),
        description: t("COMMON_SHARE_DESCRIPTION"),
      },
    },
    {
      Component: RedditShareButton,
      Icon: RedditIcon,
      props: {
        title: t("COMMON_SHARE_TITLE"),
      },
    },
    {
      Component: TelegramShareButton,
      Icon: TelegramIcon,
      props: {
        title: t("COMMON_SHARE_TITLE"),
      },
    },
    {
      Component: TwitterShareButton,
      Icon: TwitterIcon,
      props: {
        title: t("COMMON_SHARE_TITLE"),
        via: "mind_r_ai",
        hashtags: t("COMMON_SHARE_HASHTAGS").split(","),
      },
    },
    {
      Component: WhatsappShareButton,
      Icon: WhatsappIcon,
      props: {
        title: t("COMMON_SHARE_TITLE"),
        separator: " ",
      },
    },
  ]

  return (
    <div
      className="flex justify-center items-center"
      style={{
        height: "calc(100vh - 100px)",
      }}
    >
      <div>
        <h1 className="text-xl mb-8">{t("COMMON_SHARE_CTA")}</h1>
        <div>
          <FormInput type="text" showCopyButton={true} value={WEBSITE_URL} />
        </div>

        <div>
          <div className="text-lg mb-2 mt-10">{t("COMMON_SHARE_SUBTITLE")}</div>
          <div>
            {shareButtonsData.map(({ Component, Icon, props }, index) => (
              <Component key={index} {...props} url={WEBSITE_URL}>
                <Icon round={true} size="45px" style={{ marginRight: "5px" }} />
              </Component>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export function useShare() {
  const { openModal } = useModal()
  const { t } = useTranslation("common")

  const openShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t("COMMON_SHARE_SUBJECT"),
          text: t("COMMON_SHARE_BODY"),
          url: WEBSITE_URL,
        })
      } catch (error) {
        console.error("Error sharing:", error)
        openFallbackModal()
      }
    } else {
      openFallbackModal()
    }
  }

  const openFallbackModal = () => {
    openModal(<ShareContent />, {
      closeable: true,
      fullScreen: true,
      fullWidth: true,
      maxWidth: false,
      style: {
        width: "100vw",
      },
    })
  }

  return { openShare }
}
