import { createTheme, ThemeProvider } from "@mui/material/styles"
import { green } from "@mui/material/colors"

const theme = createTheme({
  palette: {
    primary: {
      main: green[600],
    },
  },
})

export function ThemeMUIProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
