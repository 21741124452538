import Image from "next/image"
import { useTranslation } from "next-i18next"
import useUser from "../hooks/useUser"
import Link from "next/link"
import HeaderUserMenu from "./HeaderUserMenu"
import {
  goToHome,
  goAboutUs,
  goToSignIn,
  goToCareers,
  goStartSession,
  getBlogHref,
} from "../utils/browser"
import { CountryAndLanguageSelectorWrapper } from "../components/CountryAndLanguageSelector/"
import MenuFullItem from "./MenuFullItem"
import FollowUs from "./FollowUs"
import clsx from "clsx"
import { useRouter } from "next/router"

import Styles from "./MenuFull.module.scss"
import { Button } from "@mui/material"

const MenuFull = ({ closeModal, displayStartSession }) => {
  const { t } = useTranslation("common")
  const { isLoggedIn } = useUser()
  const { locale } = useRouter()

  const language = locale.substring(0, 2)

  return (
    <div className={Styles.Wrapper}>
      <div className={Styles.RowToCol}>
        <div className={Styles.Col}>
          <Image
            src={"/assets/logo-white.png"}
            onClick={goToHome}
            width="150"
            height="100"
            style={{
              width: "150px",
              cursor: "pointer",
            }}
            alt={t("COMMON_COMPANY_LOGO")}
          />
        </div>
        <div className={Styles.Col}>
          <HeaderUserMenu displayStartSession={displayStartSession} />
        </div>
      </div>
      <div className={Styles.Row}>
        <div className={Styles.LanguageAndClose}>
          <CountryAndLanguageSelectorWrapper />
          <Button className={Styles.Close} onClick={closeModal}>
            <Image
              src={"/assets/icon-close-white.svg"}
              width="24"
              height="24"
              alt={t("COMMON_COMPANY_LOGO")}
            />
          </Button>
        </div>
      </div>
      <div className={clsx(Styles.Row, Styles.Slogan)}>
        {t("FOOTER_LOGO_SLOGAN")}
      </div>
      <div className={clsx(Styles.Row, Styles.Items)}>
        {isLoggedIn() ? (
          <MenuFullItem
            imgSrc="/assets/icon-login.svg"
            title={t("COMMON_TALK_TO_SOLACE_NOW")}
            subTitle={t("COMMON_START_NEW_SESSION")}
            onClick={goStartSession}
          />
        ) : (
          <MenuFullItem
            imgSrc="/assets/icon-login.svg"
            title={t("FOOTER_THERAPY_LOGIN")}
            subTitle={t("COMMON_THERAPY_LOGIN_SUBTITLE")}
            onClick={goToSignIn}
          />
        )}
        <MenuFullItem
          imgSrc="/assets/icon-about-us.svg"
          title={t("FOOTER_ABOUT_US")}
          subTitle={t("COMMON_ABOUT_US_SUBTITLE")}
          onClick={goAboutUs}
        />
        {/* <MenuFullItem
          imgSrc="/assets/icon-blog.svg"
          title={t("COMMON_BLOG")}
          subTitle={t("COMMON_BLOG_SUBTITLE")}
          onClick={goToBlog}
        /> */}
        <MenuFullItem
          imgSrc="/assets/icon-careers.svg"
          title={t("FOOTER_CAREERS")}
          subTitle={t("COMMON_CAREERS_SUBTITLE")}
          onClick={goToCareers}
        />
        {/* <MenuFullItem
          imgSrc="/assets/icon-ai-therapy.svg"
          title={t("FOOTER_AI_THERAPY_INSIGHTS")}
          subTitle={t("COMMON_AI_THERAPY_INSIGHTS_SUBTITLE")}
          onClick={goToMedium}
        /> */}
        <Link href={getBlogHref(language)}>
          <MenuFullItem
            imgSrc="/assets/icon-mental-health-insights.svg"
            title={t("COMMON_MENTAL_HEALTH_INSIGHTS")}
            subTitle={t("COMMON_MENTAL_HEALTH_INSIGHTS_SUBTITLE")}
          />
        </Link>
        {/* <MenuFullItem
          imgSrc={"/assets/icon-about-us.svg"}
          title={t("COMMON_MENTAL_HEALTH_INSIGHTS")}
          subTitle={t("COMMON_MENTAL_HEALTH_INSIGHTS_SUBTITLE")}
          onClick={() => goToHome()}
        /> */}
      </div>
      <div className={Styles.Row}>
        <FollowUs />
      </div>
      <div className={Styles.Row}>
        <div className={Styles.Col}>
          <Link href="/privacy-policy">{t("FOOTER_PRIVACY_POLICY")}</Link>
          <Link href="terms-and-conditions">{t("FOOTER_TERMS_OF_USE")}</Link>
        </div>
      </div>
    </div>
  )
}

export default MenuFull
