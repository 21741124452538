import Tooltip from "@mui/material/Tooltip"
import Image from "next/image"
import { goToPinterest, goToInstagram, goToTwitter } from "../utils/browser"
import { useTranslation } from "next-i18next"
import styles from "./FollowUs.module.scss"

export default function FollowUs() {
  const { t } = useTranslation("common")

  return (
    <div>
      <span className={styles.Title}>{t("FOOTER_FOLLOW_US")}</span>
      <div className={styles.SocialIcons}>
        {/* <Tooltip title={t("FOOTER_VISIT_US_ON_TWITTER")}>
          <Image
            src="/assets/icon-footer-twitter.svg"
            width="24"
            height="24"
            onClick={goToTwitter}
            alt={t("FOOTER_VISIT_US_ON_TWITTER")}
          />
        </Tooltip>
        <Tooltip title={t("FOOTER_VISIT_US_ON_INSTAGRAM")}>
          <Image
            src="/assets/icon-footer-instagram.svg"
            width="24"
            onClick={goToInstagram}
            height="24"
            alt={t("FOOTER_VISIT_US_ON_INSTAGRAM")}
          />
        </Tooltip> */}
        {/* <Image
                src="/assets/icon-footer-facebook.svg"
                width="24"
                height="24"
                alt={t("FOOTER_VISIT_US_ON_FACEBOOK")}
              /> */}
        <Tooltip title={t("FOOTER_VISIT_US_ON_PINTEREST")}>
          <Image
            src="/assets/icon-footer-pinterest.svg"
            onClick={goToPinterest}
            width="24"
            height="24"
            alt={t("FOOTER_VISIT_US_ON_PINTEREST")}
          />
        </Tooltip>
      </div>
    </div>
  )
}
