import { useModal } from "./useModal"
import MenuFull from "../components/MenuFull"

export function useMenu() {
  const { openModal, closeModal } = useModal()

  const openMenu = () => {
    openModal(<MenuFull closeModal={closeModal} />, {
      closeable: false,
      fullScreen: true,
      fullWidth: true,
      maxWidth: false,
      style: {
        width: "100vw",
      },
    })
  }

  return { openMenu }
}
