import { Auth, Hub } from "aws-amplify"

const signInWithGoogle = async () => {
  try {
    await Auth.federatedSignIn({ provider: "Google" })
  } catch (error) {
    console.error("Error signing in with Google:", error)
  }
}

const authListener = () => {
  Hub.listen("auth", (data) => {
    const { payload } = data

    if (payload.event === "signIn") {
      localStorage.setItem("justLoggedIn", "true")
    }
    if (payload.event === "oAuthSignOut") {
      localStorage.setItem("justLoggedOut", "true")
    }
  })
}

const checkAuth = async () => {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch (error) {
    return false
  }
}

export { signInWithGoogle, authListener, checkAuth }
