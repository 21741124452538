import { useTranslation } from "next-i18next"
import Image from "next/image"

import { useMenu } from "../hooks/useMenu"

import Styles from "./Header.module.scss"

export default function HeaderMenu({ mode = "mind-r" }) {
  const { t } = useTranslation("common")
  const { openMenu } = useMenu(mode)

  return (
    <div className={Styles.HeaderMenuIcon} onClick={openMenu}>
      <Image
        src="/assets/menu-right-alt.svg"
        width="24"
        height="24"
        alt={t("COMMON_OPEN_MENU")}
      />
    </div>
  )
}
