import { useEffect, useRef } from "react"
import useUser from "../hooks/useUser"
import { awsConfig } from "../lib/constants"
import { Amplify, Hub } from "aws-amplify"

function getUpdatedAwsConfig() {
  const getUrlLanguage = () => {
    const languagePattern = /\/([a-z]{2}-[A-Z]{2})/
    const match =
      typeof window !== "undefined"
        ? window.location.pathname.match(languagePattern)
        : null

    return match ? match[1] : null
  }

  const language = getUrlLanguage()
  const baseRedirectUrl =
    typeof window !== "undefined"
      ? `${window.location.protocol}//${window.location.host}/`
      : ""

  const redirectURL = language
    ? `${baseRedirectUrl}${language}`
    : baseRedirectUrl

  const redirectSignIn = language
    ? `${baseRedirectUrl}${language}/start-session${
        window.location.href.includes("voxme") ? "-voxme" : ""
      }?type=classic`
    : baseRedirectUrl

  return {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignOut: redirectURL,
      redirectSignIn,
    },
    ssr: true,
  }
}

const Auth = ({ children }) => {
  const { updateUserStatus } = useUser()
  const updateCall = useRef(null)

  useEffect(() => {
    updateUserStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      Amplify.configure(getUpdatedAwsConfig())

      Hub.listen("auth", ({ payload: { event } }) => {
        if (event === "tokenRefresh") {
          if (updateCall.current) {
            clearTimeout(updateCall.current)
          }

          updateCall.current = setTimeout(() => {
            console.log("Token has been refreshsed")
            updateUserStatus()
          }, 150)
        }
      })
    }
  }, [updateUserStatus])

  return children
}

export default Auth
