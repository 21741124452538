const Navigator = typeof window !== "undefined" ? window.navigator : null

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  (Navigator || {}).userAgent || ""
)

export const goToHome = () => {
  const currentUrl = new URL(window.location.href)
  let firstPathSegment = currentUrl.pathname.split("/")[1]

  // Use a regular expression to validate the language/country code
  const regex = /^[a-z]{2}-[A-Z]{2}$/

  let homeUrl

  if (firstPathSegment && regex.test(firstPathSegment)) {
    homeUrl = buildAbsoluteUrl(`${firstPathSegment}`)
  } else {
    homeUrl = buildAbsoluteUrl("")
  }

  // Only change location if not already on the home page
  if (window.location.href !== homeUrl) {
    window.location.href = homeUrl
  }
}

export const goToVoxme = () => {
  window.location.href = "https://voxme.coach"
}

export const goToActionPoints = (language, sessionId) => {
  window.location.href = buildAbsoluteUrl(
    `${language}/session-action-points?sessionId=${sessionId}`
  )
}

export const goToFounderLink = () => window.open("https://linktr.ee/rxluz")
export const goToTwitter = () => window.open("https://twitter.com/mind_r_ai")
export const goToInstagram = () =>
  window.open("https://www.instagram.com/mind_r_ai/")

export const goToLinkedin = () =>
  window.open("https://www.linkedin.com/company/mind-r-ai/")

export const goToPinterest = () =>
  window.open("https://www.pinterest.ie/mind_r_ai/_created")

export const goToReview = (language, sessionId) => {
  window.location.href = buildAbsoluteUrl(
    `${language}/session-review?sessionId=${sessionId}`
  )
}

export const goToSignUp = () => {
  window.location.href = buildAbsoluteUrl(`sign-up`, true)
}

export const goToSignIn = () => {
  window.location.href = buildAbsoluteUrl(`sign-in`, true)
}

export const goToSignUpVoxme = () => {
  window.location.href = buildAbsoluteUrl(`sign-up-voxme`, true)
}

export const goToSignInVoxme = () => {
  window.location.href = buildAbsoluteUrl(`sign-in-voxme`, true)
}

export const goStartSession = () => {
  window.location.href = buildAbsoluteUrl(`start-session?type=classic`, true)
}

export const getBlogHref = (language) => {
  const blogSlugs = {
    de: "einblicke-in-die-psychische-gesundheit",
    en: "mental-health-insights",
    es: "perspectivas-de-salud-mental",
    fr: "apercus-de-la-sante-mentale",
    it: "intuizioni-sulla-salute-mentale",
    pt: "percepcoes-sobre-saude-mental",
  }

  const blogSlug = blogSlugs[language]
  const blogUrl = `https://mind-r.ai/explore/${blogSlug}`
  return blogUrl
}

export const goToMedium = () => {
  const blogUrl = `https://ai-therapy-insights.mind-r.ai/`
  window.open(blogUrl)
}

export const goToCareers = () => {
  window.open(`https://mind-r.ai/explore/en/resource/careers/`)
}

export const goStartAnonymousSession = () => {
  window.location.href = buildAbsoluteUrl(`start-session?type=anonymous`, true)
}

export const goToDepressionTestPHQ9 = () => {
  window.location.href = buildAbsoluteUrl("depression-test-phq-9-start", true)
}

export const goToADHDTestASRSV1 = () => {
  window.location.href = buildAbsoluteUrl("online-adhd-testing-start", true)
}

export const goToAnxietyTest = () => {
  window.location.href = buildAbsoluteUrl("anxiety-test-start", true)
}

export const goToStressTestPSS = () => {
  window.location.href = buildAbsoluteUrl("stress-test-start", true)
}

export const goToStressTestPSSLandingPage = () => {
  window.location.href = buildAbsoluteUrl("stress-test", true)
}
export const goToAnxietyTestLandingPage = () => {
  window.location.href = buildAbsoluteUrl("anxiety-test", true)
}

export const goToADHDTestASRSV1LandingPage = () => {
  window.location.href = buildAbsoluteUrl("online-adhd-testing", true)
}

export const goToFreeDepressionTest = () => {
  window.location.href = buildAbsoluteUrl("free-depression-test", true)
}

export const goAboutUs = () => {
  window.location.href = buildAbsoluteUrl("about-us", true)
}

export const refresh = () => {
  window.location.reload()
}

export const isTouchDevice = () =>
  typeof window !== "undefined" &&
  ("ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0)

// utils/isMobile.js
export const isMobile = () => {
  let ua

  if (typeof navigator === "undefined") {
    // Estamos no lado do servidor, então usamos a variável global
    ua = global.userAgent || ""
  } else {
    // Estamos no lado do cliente, então usamos o objeto `navigator`
    ua = navigator.userAgent
  }

  const isAndroid = /Android/i.test(ua)
  const isiOS = /iPhone|iPad|iPod/i.test(ua)
  const isTablet = /Tablet/i.test(ua)

  if (isAndroid || isiOS) {
    if (typeof window !== "undefined") {
      // Estamos no lado do cliente, então podemos verificar o tamanho da tela
      const screenWidth = window.screen.width
      const screenHeight = window.screen.height
      const screenSize = screenWidth * screenHeight

      // Define a threshold to differentiate between phones and tablets
      const tabletThreshold = 750000 // e.g. 1024 x 768 pixels

      if (!isTablet && screenSize <= tabletThreshold) {
        return true
      }
    }
  }

  return false
}

export const isAllowedBrowserAndOS = (browser, os) => {
  const allowedBrowsers = ["Chrome", "Safari", "Mobile Safari"]
  const isAllowedBrowser = allowedBrowsers.includes(browser.name)
  const isDesktop = os.name !== "Android"
  const isIOS = os.name === "iOS"

  return (isAllowedBrowser && isDesktop) || (isIOS && isAllowedBrowser)
}

export const isRecognitionSupported = () =>
  "SpeechRecognition" in window || "webkitSpeechRecognition" in window

export const buildAbsoluteUrl = (path, keepLanguage = false) => {
  if (typeof window === "undefined") {
    return path
  }

  const origin = window.location.origin
  const defaultLanguage = "en-US"
  const languageRegex = /^[a-z]{2}-[A-Z]{2}$/

  // Get the language from the current URL
  const urlPath = window.location.pathname.split("/")
  let language = defaultLanguage

  // Check if the first part of the URL path is a language code
  if (urlPath.length > 1 && languageRegex.test(urlPath[1])) {
    language = urlPath[1]
  }

  return `${origin}${keepLanguage ? "/" + language : ""}/${path}`
}

export const urlContainsLocale = (url, supportedLocales = []) => {
  const nonLocalizedPaths = [
    "/_next/", // Next.js build assets
    "/static/", // Static assets
    "/public/", // Public folder assets
    "/api/", // API routes
    "/assets/", // API routes
  ]

  if (nonLocalizedPaths.some((path) => url.includes(path))) {
    return true
  }

  return (supportedLocales || []).some((locale) => url.includes(`/${locale}`))
}

export const getUrlLanguage = () => {
  const languagePattern = /\/([a-z]{2}-[A-Z]{2})/
  const match =
    typeof window !== "undefined"
      ? window.location.pathname.match(languagePattern)
      : null

  return match ? match[1] : null
}
