const countriesData = {
  defaultLanguages: ["de-DE", "es-ES", "en-US", "fr-FR", "it-IT", "pt-BR"],
  female: [
    {
      value: "de-AT",
      label: "Austria (German)",
      voice: "de-AT-IngridNeural",
    },
    {
      value: "de-CH",
      label: "Switzerland (German)",
      voice: "de-CH-LeniNeural",
    },
    {
      value: "de-DE",
      label: "Germany (German)",
      voice: "de-DE-AmalaNeural",
    },
    {
      value: "en-AU",
      label: "Australia (English)",
      voice: "en-AU-AnnetteNeural",
    },
    {
      value: "en-CA",
      label: "Canada (English)",
      voice: "en-CA-ClaraNeural",
    },
    {
      value: "en-GB",
      label: "United Kingdom (English)",
      voice: "en-GB-AbbiNeural",
    },
    {
      value: "en-IE",
      label: "Ireland (English)",
      voice: "en-IE-EmilyNeural",
    },
    {
      value: "en-IN",
      label: "India (English)",
      voice: "en-IN-NeerjaNeural",
    },
    {
      value: "en-NZ",
      label: "New Zealand (English)",
      voice: "en-NZ-MollyNeural",
    },
    {
      value: "en-US",
      label: "United States (English)",
      voice: "en-US-ElizabethNeural",
    },
    {
      value: "en-ZA",
      label: "South Africa (English)",
      voice: "en-ZA-LeahNeural",
    },
    {
      value: "es-AR",
      label: "Argentina (Spanish)",
      voice: "es-AR-ElenaNeural",
    },
    {
      value: "es-CL",
      label: "Chile (Spanish)",
      voice: "es-CL-CatalinaNeural",
    },
    {
      value: "es-CO",
      label: "Colombia (Spanish)",
      voice: "es-CO-SalomeNeural",
    },
    {
      value: "es-ES",
      label: "Spain (Spanish)",
      voice: "es-ES-AbrilNeural",
    },
    {
      value: "es-MX",
      label: "Mexico (Spanish)",
      voice: "es-MX-BeatrizNeural",
    },
    {
      value: "fr-BE",
      label: "Belgium (French)",
      voice: "fr-BE-CharlineNeural",
    },
    // {
    //   value: "fr-CA",
    //   label: "Canada (French)",
    //   voice: "fr-CA-SylvieNeural",
    // },
    {
      value: "fr-CH",
      label: "Switzerland (French)",
      voice: "fr-CH-ArianeNeural",
    },
    {
      value: "fr-FR",
      label: "France (French)",
      voice: "fr-FR-BrigitteNeural",
    },
    {
      value: "it-IT",
      label: "Italy (Italian)",
      voice: "it-IT-ElsaNeural",
    },
    {
      value: "pt-BR",
      label: "Brazil (Portuguese)",
      voice: "pt-BR-ManuelaNeural",
    },
    {
      value: "pt-PT",
      label: "Portugal (Portuguese)",
      voice: "pt-PT-FernandaNeural",
    },
  ],
  male: [
    {
      value: "de-AT",
      label: "Austria (German)",
      voice: "de-AT-JonasNeural",
    },
    {
      value: "de-CH",
      label: "Switzerland (German)",
      voice: "de-CH-JanNeural",
    },
    {
      value: "de-DE",
      label: "Germany (German)",
      voice: "de-DE-ConradNeural",
    },
    {
      value: "en-AU",
      label: "Australia (English)",
      voice: "en-AU-WilliamNeural",
    },
    {
      value: "en-CA",
      label: "Canada (English)",
      voice: "en-CA-LiamNeural",
    },
    {
      value: "en-GB",
      label: "United Kingdom (English)",
      voice: "en-GB-RyanNeural",
    },
    {
      value: "en-IE",
      label: "Ireland (English)",
      voice: "en-IE-ConnorNeural",
    },
    {
      value: "en-IN",
      label: "India (English)",
      voice: "en-IN-PrabhatNeural",
    },
    {
      value: "en-NZ",
      label: "New Zealand (English)",
      voice: "en-NZ-MitchellNeural",
    },
    {
      value: "en-US",
      label: "United States (English)",
      voice: "en-US-GuyNeural",
    },
    {
      value: "en-ZA",
      label: "South Africa (English)",
      voice: "en-ZA-LukeNeural",
    },
    {
      value: "es-AR",
      label: "Argentina (Spanish)",
      voice: "es-AR-TomasNeural",
    },
    {
      value: "es-CL",
      label: "Chile (Spanish)",
      voice: "es-CL-LorenzoNeural",
    },
    {
      value: "es-CO",
      label: "Colombia (Spanish)",
      voice: "es-CO-GonzaloNeural",
    },
    {
      value: "es-ES",
      label: "Spain (Spanish)",
      voice: "es-ES-AlvaroNeural",
    },
    {
      value: "es-MX",
      label: "Mexico (Spanish)",
      voice: "es-MX-JorgeNeural",
    },
    {
      value: "fr-BE",
      label: "Belgium (French)",
      voice: "fr-BE-GerardNeural",
    },
    {
      value: "fr-CH",
      label: "Switzerland (French)",
      voice: "fr-CH-FabriceNeural",
    },
    {
      value: "fr-FR",
      label: "France (French)",
      voice: "fr-FR-HenriNeural",
    },
    {
      value: "it-IT",
      label: "Italy (Italian)",
      voice: "it-IT-DiegoNeural",
    },
    {
      value: "pt-BR",
      label: "Brazil (Portuguese)",
      voice: "pt-BR-AntonioNeural",
    },
    {
      value: "pt-PT",
      label: "Portugal (Portuguese)",
      voice: "pt-PT-DuarteNeural",
    },
  ],
}

module.exports = countriesData
