import { useTranslation } from "next-i18next"

import { useShare } from "../hooks/useShare"
import { authListener } from "../utils/auth"
import { useEffect, useMemo } from "react"

import { useRouter } from "next/router"
import HeaderUserMenu from "./HeaderUserMenu"
import Image from "next/image"
import Link from "next/link"
import { goToHome, goToVoxme } from "../utils/browser"
import Style from "./Header.module.scss"
import useUser from "../hooks/useUser"
import HeaderMenu from "./HeaderMenu"
import clsx from "clsx"

export default function Header({
  mode = "mind-r",
  displayLogo = true,
  fixedHeader = true,
  transparent = false,
  displayMenuItems = true,
  displayProductHunt = false,
  logoUrl: externalLogoUrl = "/assets/logo-black.png",
  displayStartSession = true,
} = {}) {
  const { t } = useTranslation("common")
  const { updateUserStatus, checkLogoutMessage } = useUser()

  const { openShare } = useShare()

  const { locale: language } = useRouter()

  const logoUrl = mode === "mind-r" ? externalLogoUrl : "/assets/voxme-logo.png"

  const onClickLogo = mode === "mind-r" ? goToHome : goToVoxme

  useEffect(() => {
    authListener()
    updateUserStatus()
    checkLogoutMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <header
      className={`${
        fixedHeader ? "fixed" : "absolute"
      } t-0  width-full flex justify-center`}
      style={{
        zIndex: 1000,
        ...(transparent
          ? {}
          : {
              backdropFilter: "blur(7px)",
              backgroundColor: "#ffffffbf",
            }),
      }}
    >
      <div className={Style.Header}>
        {displayLogo && (
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <Image
              src={logoUrl}
              onClick={onClickLogo}
              width="200"
              height="150"
              style={{
                width: "150px",
                cursor: "pointer",
              }}
              alt={t("COMMON_COMPANY_LOGO")}
            />
          </div>
        )}

        {displayMenuItems && mode === "mind-r" && (
          <div className={clsx(Style.HeaderMenu, "hide-on-mobile")}>
            <Link href="/about-us">{t("FOOTER_ABOUT_US")}</Link>
            <Link href={`/${language}/#how-it-works`}>
              {t("HOME_HOW_IT_WORKS_TITLE")}
            </Link>
            <Link href={`/${language}/#FAQ`}>{t("HOME_FAQS")}</Link>
            <a href="#" onClick={openShare}>
              {t("COMMON_SHARE_WITH_FRIENDS")}
            </a>
          </div>
        )}

        <div className={Style.HeaderButtons}>
          {displayProductHunt && (
            <div className={clsx(Style.ProductHunt, "hide-on-mobile")}>
              <Image
                src="/assets/logo-product-hunt.svg"
                onClick={goToHome}
                width="106"
                height="150"
                style={{
                  width: "91px",
                  cursor: "pointer",
                }}
                alt={t("COMMON_COMPANY_LOGO")}
              />
            </div>
          )}

          <HeaderUserMenu
            mode={mode}
            displayStartSession={displayStartSession}
          />

          {mode === "mind-r" && (
            <HeaderMenu displayStartSession={displayStartSession} />
          )}
        </div>
      </div>
    </header>
  )
}
